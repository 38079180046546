import api from 'api'

const FETCH_REQUEST = 'realhub/agencyDashboard/overview/deadlines/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/agencyDashboard/overview/deadlines/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/agencyDashboard/overview/deadlines/FETCH_FAILURE'

// Initial State
const initialState = {
  errors: [],
  loaded: false,
  loading: false,
  publicationDeadlines: [],
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    publicationDeadlines: payload.publicationDeadlines,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadPublicationDeadlines(options = {}){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/agency/publication_editions/deadlines.json', options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok) return Promise.reject(json)

        dispatch(fetchSuccess({ publicationDeadlines: json }))

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))

        return { success: false, result: json }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { publicationDeadlines, type, errors } = action

  switch (type){
    case FETCH_REQUEST:
      return { ...state, loading: true }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        publicationDeadlines: publicationDeadlines || state.publicationDeadlines,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    default:
      return state
  }
}
