const CHANGE_FILTER = 'realhub/agencyDashboard/campaignDashboard/CHANGE_FILTER'

const initialState = {
  campaignStatusFilter: 'all',
  campaignTypeFilter: 'all',
  filterString: '',
  selectedAgentIds: [],
}

// Actions
export function changeFilter(name, value){
  return {
    type: CHANGE_FILTER,
    name,
    value,
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type } = action

  switch (type){
    case CHANGE_FILTER:{
      const change = {}
      change[action.name] = action.value

      return {
        ...state,
        ...change,
      }
    }
    default:
      return state
  }
}
