import { schema } from 'normalizr'

// Circular Dependencies - Use the define method
// const item = new schema.Entity('item');
// const child = new schema.Entity('child');
// item.define({ children: [child] });

// Define Entities
const activity = new schema.Entity('activities')
const adCampaign = new schema.Entity('adCampaigns')
const adCopy = new schema.Entity('adCopies')
const address = new schema.Entity('addresses')
const agency = new schema.Entity('agencies')
const agencyContact = new schema.Entity('agencyContacts')
const agencyOrderBundle = new schema.Entity('agencyOrderBundles')
const agencyPaymentMethod = new schema.Entity('agencyPaymentMethods')
const agencyPortal = new schema.Entity('agencyPortals')
const agencyProvider = new schema.Entity('agencyProviders')
const agencyProviderDeadlineGroup = new schema.Entity('agencyProviderDeadlineGroups')
const agencyProviderPackage = new schema.Entity('agencyProviderPackages')
const agencyProviderPriceOverride = new schema.Entity('agencyProviderPriceOverrides')
const agencyPublication = new schema.Entity('agencyPublications')
const agencyTag = new schema.Entity('agencyTags')
const agencyTemplatePage = new schema.Entity('agencyTemplatePages')
const agent = new schema.Entity('agents')
const apiKey = new schema.Entity('apiKeys')
const apiLog = new schema.Entity('apiLogs')
const appOrderPrices = new schema.Entity('appOrderPrices')
const artwork = new schema.Entity('artworks')
const artworkComment = new schema.Entity('artworkComments')
const artworkPage = new schema.Entity('artworkPages')
const artworkPageItem = new schema.Entity('artworkPageItems')
const artworkReview = new schema.Entity('artworkReviews')
const assetLibrary = new schema.Entity('assetLibraries')
const attachment = new schema.Entity('attachments')
const booking = new schema.Entity('bookings')
const brand = new schema.Entity('brands')
const brandColour = new schema.Entity('brandColours')
const brandGroup = new schema.Entity('brandGroups')
const brandGroupMember = new schema.Entity('brandGroupMembers')
const brandGroupProvider = new schema.Entity('brandGroupProviders')
const brandGroupPublication = new schema.Entity('brandGroupPublications')
const brandTheme = new schema.Entity('brandThemes')
const brandThemeTemplatePage = new schema.Entity('brandThemeTemplatePages')
const campaign = new schema.Entity('campaigns')
const campaignCategory = new schema.Entity('campaignCategories')
const campaignCommercialCategory = new schema.Entity('campaignCommercialCategories')
const campaignComparable = new schema.Entity('campaignComparables')
const campaignContact = new schema.Entity('campaignContacts')
const campaignContribution = new schema.Entity('campaignContributions')
const campaignDocument = new schema.Entity('campaignDocuments')
const campaignInspection = new schema.Entity('campaignInspections')
const campaignItem = new schema.Entity('campaignItems')
const campaignNote = new schema.Entity('campaignNotes')
const campaignPortal = new schema.Entity('campaignPortals')
const campaignPortalSync = new schema.Entity('campaignPortalSyncs')
const campaignProposal = new schema.Entity('campaignProposals')
const campaignProposalSection = new schema.Entity('campaignProposalSections')
const campaignQuote = new schema.Entity('campaignQuotes')
const campaignQuoteItem = new schema.Entity('campaignQuoteItems')
const campaignQuoteSection = new schema.Entity('campaignQuoteSections')
const campaignQuoteTemplate = new schema.Entity('campaignQuoteTemplates')
const campaignQuoteTemplateItem = new schema.Entity('campaignQuoteTemplateItems')
const campaignRuralCategory = new schema.Entity('campaignRuralCategories')
const campaignSaleMethod = new schema.Entity('campaignSaleMethods')
const campaignStatus = new schema.Entity('campaignStatuses')
const campaignType = new schema.Entity('campaignTypes')
const caseStudy = new schema.Entity('caseStudies')
const chart = new schema.Entity('charts')
const comment = new schema.Entity('comments')
const contact = new schema.Entity('contacts')
const contract = new schema.Entity('contracts')
const conversation = new schema.Entity('conversations')
const courier = new schema.Entity('couriers')
const creditNote = new schema.Entity('creditNotes')
const creditNoteItem = new schema.Entity('creditNoteItems')
const customDeadline = new schema.Entity('customDeadlines')
const customField = new schema.Entity('customFields')
const customFieldSet = new schema.Entity('customFieldSets')
const customItem = new schema.Entity('customItems')
const customItemGroup = new schema.Entity('customItemGroups')
const customShortcode = new schema.Entity('customShortcodes')
const dataStoreItem = new schema.Entity('dataStoreItems')
const defaultQuoteItem = new schema.Entity('defaultQuoteItems')
const digitalComponent = new schema.Entity('digitalComponents')
const digitalPage = new schema.Entity('digitalPages')
const digitalTemplate = new schema.Entity('digitalTemplates')
const digitalTemplatePage = new schema.Entity('digitalTemplatePages')
const digitalTemplatePageItem = new schema.Entity('digitalTemplatePageItems')
const digitalTemplatePageSection = new schema.Entity('digitalTemplatePageSections')
const digitalTemplatePageSectionGroup = new schema.Entity('digitalTemplatePageSectionGroups')
const directoryListing = new schema.Entity('directoryListings')
const directoryListingType = new schema.Entity('directoryListingTypes')
const document = new schema.Entity('documents')
const documentGroup = new schema.Entity('documentGroups')
const documentSigner = new schema.Entity('documentSigners')
const event = new schema.Entity('events')
const favouriteOrder = new schema.Entity('favouriteOrders')
const favouriteOrderItem = new schema.Entity('favouriteOrderItems')
const financeApplication = new schema.Entity('financeApplications')
const font = new schema.Entity('fonts')
const fontFamily = new schema.Entity('fontFamilies')
const geoCountry = new schema.Entity('geoCountries')
const geoState = new schema.Entity('geoStates')
const geoSuburb = new schema.Entity('geoSuburbs')
const image = new schema.Entity('images')
const imageLibrary = new schema.Entity('imageLibraries')
const integration = new schema.Entity('integrations')
const interactiveImage = new schema.Entity('interactiveImages')
const interactiveImageTag = new schema.Entity('interactiveImageTags')
const invoice = new schema.Entity('invoices')
const invoiceAttachment = new schema.Entity('invoiceAttachments')
const invoiceItem = new schema.Entity('invoiceItems')
const layoutTemplate = new schema.Entity('layoutTemplates')
const loginSession = new schema.Entity('loginSessions')
const mediaLink = new schema.Entity('mediaLinks')
const microsite = new schema.Entity('microsites')
const micrositeTemplate = new schema.Entity('micrositeTemplates')
const notification = new schema.Entity('notifications')
const order = new schema.Entity('orders')
const orderBundle = new schema.Entity('orderBundles')
const orderBundleItem = new schema.Entity('orderBundleItems')
const orderItem = new schema.Entity('orderItems')
const orderItemTask = new schema.Entity('orderItemTasks')
const orderItemTaskAttachment = new schema.Entity('orderItemTaskAttachments')
const orderItemTaskType = new schema.Entity('orderItemTaskTypes')
const orderLeadTime = new schema.Entity('orderLeadTimes')
const orderTrackingCode = new schema.Entity('orderTrackingCodes')
const paginationRule = new schema.Entity('paginationRules')
const paymentMethod = new schema.Entity('paymentMethods')
const portal = new schema.Entity('portals')
const portalOption = new schema.Entity('portalOptions')
const portalOptionValue = new schema.Entity('portalOptionValues')
const priceList = new schema.Entity('priceLists')
const pricingPlan = new schema.Entity('pricingPlans')
const processingFee = new schema.Entity('processingFees')
const provider = new schema.Entity('providers')
const providerAccount = new schema.Entity('providerAccounts')
const providerDeadlineGroup = new schema.Entity('providerDeadlineGroups')
const providerDisclaimer = new schema.Entity('providerDisclaimers')
const providerOrderCode = new schema.Entity('providerOrderCodes')
const providerPackage = new schema.Entity('providerPackages')
const providerPackageItem = new schema.Entity('providerPackageItems')
const providerPriceList = new schema.Entity('providerPriceLists')
const providerPriceListItem = new schema.Entity('providerPriceListItems')
const providerPriceListItemOption = new schema.Entity('providerPriceListItemOptions')
const providerPriceListItemOptionValue = new schema.Entity('providerPriceListItemOptionValues')
const providerPriceListProductUnlock = new schema.Entity('providerPriceListProductUnlocks')
const providerProductCode = new schema.Entity('providerProductCodes')
const providerService = new schema.Entity('providerServices')
const providerTemplatePage = new schema.Entity('providerTemplatePages')
const providerZone = new schema.Entity('providerZones')
const providerZoneAgencyPrice = new schema.Entity('providerZoneAgencyPrices')
const providerZoneSuburb = new schema.Entity('providerZoneSuburbs')
const publication = new schema.Entity('publications')
const publicationBooking = new schema.Entity('publicationBookings')
const publicationBookingItem = new schema.Entity('publicationBookingItems')
const publicationCompatiblePagination = new schema.Entity('publicationCompatiblePaginations')
const publicationEdition = new schema.Entity('publicationEditions')
const publicationInspection = new schema.Entity('publicationInspections')
const publicationOption = new schema.Entity('publicationOptions')
const publicationOptionValue = new schema.Entity('publicationOptionValues')
const publicationOrder = new schema.Entity('publicationOrders')
const publicationPackage = new schema.Entity('publicationPackages')
const publicationPackageProduct = new schema.Entity('publicationPackageProducts')
const publicationPackagePublication = new schema.Entity('publicationPackagePublications')
const publicationPackageRule = new schema.Entity('publicationPackageRules')
const publicationPaginationRule = new schema.Entity('publicationPaginationRules')
const publicationProduct = new schema.Entity('publicationProducts')
const publicationSpecialDeadline = new schema.Entity('publicationSpecialDeadlines')
const publicationTemplatePage = new schema.Entity('publicationTemplatePages')
const role = new schema.Entity('roles')
const review = new schema.Entity('reviews')
const report = new schema.Entity('reports')
const savedArtwork = new schema.Entity('savedArtworks')
const savedArtworkPage = new schema.Entity('savedArtworkPages')
const scheduledReminder = new schema.Entity('scheduledReminders')
const service = new schema.Entity('services')
const serviceCategory = new schema.Entity('serviceCategories')
const serviceProduct = new schema.Entity('serviceProducts')
const serviceProductTemplatePage = new schema.Entity('serviceProductTemplatePages')
const shareLink = new schema.Entity('shareLinks')
const smsLog = new schema.Entity('smsLogs')
const status = new schema.Entity('statuses')
const subscriber = new schema.Entity('subscribers')
const system = new schema.Entity('systems')
const tag = new schema.Entity('tags')
const taskList = new schema.Entity('taskLists')
const taskListItem = new schema.Entity('taskListItems')
const taskListSection = new schema.Entity('taskListSections')
const taskListTemplate = new schema.Entity('taskListTemplates')
const taskListTemplateItem = new schema.Entity('taskListTemplateItems')
const taskListTemplateSection = new schema.Entity('taskListTemplateSections')
const team = new schema.Entity('teams')
const teamMember = new schema.Entity('teamMembers')
const templatePage = new schema.Entity('templatePages')
const templatePageAttachment = new schema.Entity('templatePageAttachments')
const templatePageCategory = new schema.Entity('templatePageCategories')
const templatePageItem = new schema.Entity('templatePageItems')
const templatePagePartial = new schema.Entity('templatePagePartials')
const trackingLink = new schema.Entity('trackingLinks')
const trainingCourse = new schema.Entity('trainingCourses')
const trainingLesson = new schema.Entity('trainingLessons')
const trainingModule = new schema.Entity('trainingModules')
const trainingSession = new schema.Entity('trainingSessions')
const user = new schema.Entity('users')
const userVerification = new schema.Entity('userVerifications')
const webhook = new schema.Entity('webhooks')

// Define Relationships
adCampaign.define({
  attachments: [attachment],
})

agency.define({
  api_keys: [apiKey],
  attachments: [attachment],
  brand,
  contacts: [agencyContact],
  contracts: [contract],
  custom_shortcodes: [customShortcode],
  deadline_groups: [agencyProviderDeadlineGroup],
  images: [image],
  payment_methods: [agencyPaymentMethod],
  portals: [agencyPortal],
  providers: [agencyProvider],
  publications: [agencyPublication],
})

agencyContact.define({
  agency,
})

agencyPaymentMethod.define({
  agency,
  payment_method: paymentMethod,
})

agencyPortal.define({
  agency,
  portal,
})

agencyProvider.define({
  agency,
  provider,
  provider_price_list: providerPriceList,
})

agencyProviderDeadlineGroup.define({
  agency,
  provider,
  provider_deadline_group: providerDeadlineGroup,
})

agencyPublication.define({
  agency,
  publication,
})

agencyTag.define({
  agency,
})

agent.define({
  media_links: [mediaLink],
  reviews: [review],
  user,
})

apiLog.define({
  webhook,
  actioned_by: user,
})

artwork.define({
  agency,
  status,
  pages: [artworkPage],
  comments: [artworkComment],
})

artworkComment.define({
  artwork,
  user,
})

artworkPage.define({
  artwork,
  templatePage,
})

artworkPageItem.define({
  artworkPage,
  templatePageItem,
})

artworkReview.define({
  artwork,
  user,
  status,
})

assetLibrary.define({
  attachments: [attachment],
})

attachment.define({
  order_item: orderItem,
})

booking.define({
  activities: [activity],
  agents: [agent],
  agency,
  order_item: orderItem,
  provider,
  related_bookings: [booking],
})

brand.define({
  attachments: [attachment],
  colours: [brandColour],
})

brandGroup.define({
  brand_group_publications: [brandGroupPublication],
  brand_group_providers: [brandGroupProvider],
  brand_group_members: [brandGroupMember],
})

brandGroupMember.define({
  brand,
  agency,
})

brandGroupProvider.define({
  brand,
  provider,
})

brandGroupPublication.define({
  brand,
  publication,
})

brandTheme.define({
  brand,
})

campaign.define({
  ad_copies: [adCopy],
  agency,
  agents: [agent],
  campaign_contacts: [campaignContact],
  campaign_quotes: [campaignQuote],
  campaign_sale_method: campaignSaleMethod,
  campaign_status: campaignStatus,
  case_studies: [caseStudy],
  comparables: [campaignComparable],
  contacts: [contact],
  contributions: [campaignContribution],
  credit_notes: [creditNote],
  custom_shortcodes: [customShortcode],
  data_store_items: [dataStoreItem],
  documents: [campaignDocument],
  events: [event],
  images: [image],
  inspections: [campaignInspection],
  invoices: [invoice],
  items: [campaignItem],
  media_links: [mediaLink],
  orders: {
    pending: [order],
    approved: [order],
  },
  reviews: [review],
  suburb: geoSuburb,
  team,
})

campaignContact.define({
  contact,
})

campaignContribution.define({
  campaign,
})

campaignInspection.define({
  campaign,
})

campaignNote.define({
  user,
})

campaignPortalSync.define({
  campaign,
})

campaignProposal.define({
  artwork,
  attachments: [attachment],
  campaign,
  campaign_quotes: [campaignQuote],
  case_studies: [caseStudy],
  digital_template: digitalTemplate,
  images: [image],
  order,
  reviews: [review],
  scheduled_reminder: scheduledReminder,
  sections: [campaignProposalSection],
  share_link: shareLink,
  sms_logs: [smsLog],
  status,
  team,
})

campaignQuote.define({
  campaign,
  items: [campaignQuoteItem],
  sections: [campaignQuoteSection],
  signers: [documentSigner],
})

campaignQuoteItem.define({
  publication,
  publication_edition: publicationEdition,
  publication_package: publicationPackage,
})

campaignQuoteSection.define({
  campaign_quote: campaignQuote,
  images: [image],
  items: [campaignQuoteItem],
})

campaignQuoteTemplate.define({
  items: [campaignQuoteTemplateItem],
  user,
})

caseStudy.define({
  campaign,
  image,
})

comment.define({
  attachments: [attachment],
  conversation,
  user,
})

contact.define({
  user,
})

contract.define({
  attachments: [attachment],
})

conversation.define({
  artwork,
  comments: [comment],
  last_comment: comment,
  subscribers: [subscriber],
  task_list_item: taskListItem,
})

creditNote.define({
  campaign,
  items: [creditNoteItem],
})

customFieldSet.define({
  fields: [customField],
})

customItemGroup.define({
  items: [customItem],
})

dataStoreItem.define({
  digital_template: digitalTemplate,
  user,
})

digitalPage.define({
  agency,
  attachments: [attachment],
  campaign,
  campaign_quotes: [campaignQuote],
  case_studies: [caseStudy],
  digital_template: digitalTemplate,
  document_group: documentGroup,
  images: [image],
  reviews: [review],
  scheduled_reminder: scheduledReminder,
  share_link: shareLink,
  sms_logs: [smsLog],
  status,
  team,
})

digitalTemplate.define({
  images: [image],
  pages: [digitalTemplatePage],
  preview_image: image,
})

digitalTemplatePage.define({
  pages: [digitalTemplatePage],
  section_groups: [digitalTemplatePageSectionGroup],
})

digitalTemplatePageSectionGroup.define({
  sections: [digitalTemplatePageSection],
})

digitalTemplatePageSection.define({
  items: [digitalTemplatePageItem],
})

digitalTemplatePageItem.define({
  items: [digitalTemplatePageItem],
})

directoryListing.define({
  service,
  directoryListingType: [directoryListingType],
})

documentGroup.define({
  documents: [document],
  signers: [documentSigner],
})

documentSigner.define({
  document_group: documentGroup,
  user,
})

favouriteOrder.define({
  agency,
  items: [favouriteOrderItem],
})

financeApplication.define({
  campaign_contribution: campaignContribution,
})

fontFamily.define({
  fonts: [font],
})

imageLibrary.define({
  cover_image: image,
  images: [image],
})

invoice.define({
  attachments: [invoiceAttachment],
  booking,
  campaign,
  items: [invoiceItem],
  package: providerPackage,
  users: [user],
})

interactiveImage.define({
  tags: [interactiveImageTag],
})

interactiveImageTag.define({
  image,
})

layoutTemplate.define({
  page_attachments: [templatePageAttachment],
  items: [templatePageItem],
})

loginSession.define({
  user,
})

micrositeTemplate.define({
  user,
})

notification.define({
  attachment,
})

order.define({
  agency,
  campaign,
  items: [orderItem],
  order_bundle: orderBundle,
  provider,
  publication,
  publication_booking: publicationBooking,
  publication_edition: publicationEdition,
  publication_package: publicationPackage,
  status,
  tracking_codes: [orderTrackingCode],
})

orderBundle.define({
  agency_order_bundles: [agencyOrderBundle],
  items: [orderBundleItem],
})

orderBundleItem.define({
  price_list: providerPriceList,
})

orderItem.define({
  agency,
  artwork,
  attachments: [attachment],
  booking,
  campaign,
  interactive_image: interactiveImage,
  last_completed_task: orderItemTask,
  microsite,
  order,
  provider,
  publication,
  publication_booking: publicationBooking,
  publication_edition: publicationEdition,
  publication_package: publicationPackage,
  service,
  service_product: serviceProduct,
  status,
  tasks: [orderItemTask],
})

orderItemTask.define({
  attachments: [orderItemTaskAttachment],
  order_item: orderItem,
  task_type: orderItemTaskType,
})

paginationRule.define({
  publication_pagination_rules: [publicationPaginationRule],
})

portal.define({
  portal_options: [portalOption],
})

portalOption.define({
  values: [portalOptionValue],
})

provider.define({
  agency_providers: [agencyProvider],
  api_keys: [apiKey],
  attachments: [attachment],
  contracts: [contract],
  order_codes: [providerOrderCode],
  product_codes: [providerProductCode],
  deadline_groups: [providerDeadlineGroup],
  disclaimers: [providerDisclaimer],
  services: [providerService],
  webhooks: [webhook],
  price_lists: [providerPriceList],
})

providerAccount.define({
  agency,
})

providerPackage.define({
  agency_provider_packages: [agencyProviderPackage],
  attachments: [attachment],
  items: [providerPackageItem],
})

providerPackageItem.define({
  price_list: providerPriceList,
})

providerPriceList.define({
  attachments: [attachment],
  items: [providerPriceListItem],
  previous_price_list: providerPriceList,
  products: [serviceProduct],
  providers: [agencyProvider],
  service,
})

providerPriceListItem.define({
  options: [providerPriceListItemOption],
})

providerPriceListItemOption.define({
  values: [providerPriceListItemOptionValue],
})

providerService.define({
  service,
})

providerZone.define({
  agency_prices: [providerZoneAgencyPrice],
  zone_suburbs: [providerZoneSuburb],
})

providerZoneSuburb.define({
  agency,
  geo_suburb: geoSuburb,
})

publication.define({
  brand_group_member: brandGroupMember,
  special_deadlines: [publicationSpecialDeadline],
  products: [publicationProduct],
  editions: [publicationEdition],
  compatible_paginations: [publicationCompatiblePagination],
  publication_options: [publicationOption],
})

publicationBooking.define({
  brand_group: brandGroup,
  items: [publicationBookingItem],
  preflighted_by: user,
  publication,
  publication_edition: publicationEdition,
  publication_order: publicationOrder,
  publication_product: publicationProduct,
  status,
  user,
})

publicationBookingItem.define({
  artwork,
  order,
  publication,
  publication_booking: publicationBooking,
})

publicationInspection.define({
  campaign,
  publication,
  publication_edition: publicationEdition,
  status,
})

publicationOption.define({
  values: [publicationOptionValue],
})

publicationOrder.define({
  bookings: [publicationBooking],
  brand_group: brandGroup,
  publication,
  publication_edition: publicationEdition,
})

publicationPackage.define({
  products: [publicationPackageProduct],
  publications: [publicationPackagePublication],
  rules: [publicationPackageRule],
})

review.define({
  campaign,
})

savedArtwork.define({
  pages: [savedArtworkPage],
  user,
})

savedArtworkPage.define({
  user,
})

service.define({
  categories: [serviceCategory],
  products: [serviceProduct],
})

serviceCategory.define({
  service,
})

serviceProduct.define({
  attachments: [attachment],
  items: [providerPriceListItem],
})

subscriber.define({
  user,
})

system.define({
  webhooks: [webhook],
})

taskList.define({
  items: [taskListItem],
  sections: [taskListSection],
})

taskListItem.define({
  attachments: [attachment],
  campaign,
  task_list: taskList,
  task_list_template_item: taskListTemplateItem,
})

taskListTemplate.define({
  items: [taskListTemplateItem],
  sections: [taskListTemplateSection],
})

taskListTemplateSection.define({
  items: [taskListTemplateItem],
})

team.define({
  agent_members: [teamMember],
  cover_image: image,
  image_library: imageLibrary,
  team_members: [teamMember],
})

teamMember.define({
  team,
  user,
})

templatePage.define({
  agency_template_pages: [agencyTemplatePage],
  items: [templatePageItem],
  page_attachments: [templatePageAttachment],
  provider_template_pages: [providerTemplatePage],
  publication_template_pages: [publicationTemplatePage],
  service_product_template_pages: [serviceProductTemplatePage],
  status,
})

templatePageItem.define({
  templatePage,
})

templatePagePartial.define({
  items: [templatePageItem],
})

trainingCourse.define({
  modules: [trainingModule],
  image: attachment,
})

trainingLesson.define({
  attachments: [attachment],
  lesson_alias: trainingLesson,
  trainingCourse,
})

trainingModule.define({
  lessons: [trainingLesson],
  image: attachment,
})

user.define({
  agents: [agent],
  api_keys: [apiKey],
  contact,
  custom_shortcodes: [customShortcode],
  role,
  image,
  images: [image],
  integrations: [integration],
  media_links: [mediaLink],
  team_members: [teamMember],
})

userVerification.define({
  attachments: [attachment],
  campaign,
  user,
})

// Export Schemas
export const Schemas = {
  ACTIVITY: activity,
  ACTIVITY_ARRAY: [activity],
  ADDRESS: address,
  ADDRESS_ARRAY: [address],
  AD_CAMPAIGN: adCampaign,
  AD_CAMPAIGN_ARRAY: [adCampaign],
  AD_COPY: adCopy,
  AD_COPY_ARRAY: [adCopy],
  AGENCY: agency,
  AGENCY_ARRAY: [agency],
  AGENCY_CONTACT: agencyContact,
  AGENCY_CONTACT_ARRAY: [agencyContact],
  AGENCY_ORDER_BUNDLE: agencyOrderBundle,
  AGENCY_ORDER_BUNDLE_ARRAY: [agencyOrderBundle],
  AGENCY_PAYMENT_METHOD: agencyPaymentMethod,
  AGENCY_PAYMENT_METHOD_ARRAY: [agencyPaymentMethod],
  AGENCY_PORTAL: agencyPortal,
  AGENCY_PORTAL_ARRAY: [agencyPortal],
  AGENCY_PROVIDER: agencyProvider,
  AGENCY_PROVIDER_ARRAY: [agencyProvider],
  AGENCY_PROVIDER_DEADLINE_GROUP: agencyProviderDeadlineGroup,
  AGENCY_PROVIDER_DEADLINE_GROUP_ARRAY: [agencyProviderDeadlineGroup],
  AGENCY_PROVIDER_PACKAGE: agencyProviderPackage,
  AGENCY_PROVIDER_PACKAGE_ARRAY: [agencyProviderPackage],
  AGENCY_PROVIDER_PRICE_OVERRIDE: agencyProviderPriceOverride,
  AGENCY_PROVIDER_PRICE_OVERRIDE_ARRAY: [agencyProviderPriceOverride],
  AGENCY_PUBLICATION: agencyPublication,
  AGENCY_PUBLICATION_ARRAY: [agencyPublication],
  AGENCY_TAG: agencyTag,
  AGENCY_TAG_ARRAY: [agencyTag],
  AGENCY_TEMPLATE_PAGE: agencyTemplatePage,
  AGENCY_TEMPLATE_PAGE_ARRAY: [agencyTemplatePage],
  AGENT: agent,
  AGENT_ARRAY: [agent],
  API_KEY: apiKey,
  API_KEY_ARRAY: [apiKey],
  API_LOG: apiLog,
  API_LOG_ARRAY: [apiLog],
  APP_ORDER_PRICE: appOrderPrices,
  APP_ORDER_PRICE_ARRAY: [appOrderPrices],
  ARTWORK: artwork,
  ARTWORK_ARRAY: [artwork],
  ARTWORK_COMMENT: artworkComment,
  ARTWORK_COMMENT_ARRAY: [artworkComment],
  ARTWORK_PAGE: artworkPage,
  ARTWORK_PAGE_ARRAY: [artworkPage],
  ARTWORK_PAGE_ITEM: artworkPageItem,
  ARTWORK_PAGE_ITEM_ARRAY: [artworkPageItem],
  ARTWORK_REVIEW: artworkReview,
  ARTWORK_REVIEW_ARRAY: [artworkReview],
  ASSET_LIBRARY: assetLibrary,
  ASSET_LIBRARY_ARRAY: [assetLibrary],
  ATTACHMENT: attachment,
  ATTACHMENT_ARRAY: [attachment],
  BOOKING: booking,
  BOOKING_ARRAY: [booking],
  BRAND: brand,
  BRAND_ARRAY: [brand],
  BRAND_COLOUR: brandColour,
  BRAND_COLOUR_ARRAY: [brandColour],
  BRAND_GROUP: brandGroup,
  BRAND_GROUP_ARRAY: [brandGroup],
  BRAND_GROUP_MEMBER: brandGroupMember,
  BRAND_GROUP_MEMBER_ARRAY: [brandGroupMember],
  BRAND_GROUP_PROVIDER: brandGroupProvider,
  BRAND_GROUP_PROVIDER_ARRAY: [brandGroupProvider],
  BRAND_GROUP_PUBLICATION: brandGroupPublication,
  BRAND_GROUP_PUBLICATION_ARRAY: [brandGroupPublication],
  BRAND_THEME: brandTheme,
  BRAND_THEME_ARRAY: [brandTheme],
  BRAND_THEME_TEMPLATE_PAGE: brandThemeTemplatePage,
  BRAND_THEME_TEMPLATE_PAGE_ARRAY: [brandThemeTemplatePage],
  CAMPAIGN: campaign,
  CAMPAIGN_ARRAY: [campaign],
  CAMPAIGN_CATEGORY: campaignCategory,
  CAMPAIGN_CATEGORY_ARRAY: [campaignCategory],
  CAMPAIGN_COMMERCIAL_CATEGORY: campaignCommercialCategory,
  CAMPAIGN_COMMERCIAL_CATEGORY_ARRAY: [campaignCommercialCategory],
  CAMPAIGN_COMPARABLE: campaignComparable,
  CAMPAIGN_COMPARABLE_ARRAY: [campaignComparable],
  CAMPAIGN_CONTACT: campaignContact,
  CAMPAIGN_CONTACT_ARRAY: [campaignContact],
  CAMPAIGN_CONTRIBUTION: campaignContribution,
  CAMPAIGN_CONTRIBUTION_ARRAY: [campaignContribution],
  CAMPAIGN_DOCUMENT: campaignDocument,
  CAMPAIGN_DOCUMENT_ARRAY: [campaignDocument],
  CAMPAIGN_INSPECTION: campaignInspection,
  CAMPAIGN_INSPECTION_ARRAY: [campaignInspection],
  CAMPAIGN_ITEM: campaignItem,
  CAMPAIGN_ITEM_ARRAY: [campaignItem],
  CAMPAIGN_NOTE: campaignNote,
  CAMPAIGN_NOTE_ARRAY: [campaignNote],
  CAMPAIGN_PORTAL: campaignPortal,
  CAMPAIGN_PORTAL_ARRAY: [campaignPortal],
  CAMPAIGN_PORTAL_SYNC: campaignPortalSync,
  CAMPAIGN_PORTAL_SYNC_ARRAY: [campaignPortalSync],
  CAMPAIGN_PROPOSAL: campaignProposal,
  CAMPAIGN_PROPOSAL_ARRAY: [campaignProposal],
  CAMPAIGN_PROPOSAL_SECTION: campaignProposalSection,
  CAMPAIGN_PROPOSAL_SECTION_ARRAY: [campaignProposalSection],
  CAMPAIGN_QUOTE: campaignQuote,
  CAMPAIGN_QUOTE_ARRAY: [campaignQuote],
  CAMPAIGN_QUOTE_ITEM: campaignQuoteItem,
  CAMPAIGN_QUOTE_ITEM_ARRAY: [campaignQuoteItem],
  CAMPAIGN_QUOTE_SECTION: campaignQuoteSection,
  CAMPAIGN_QUOTE_SECTION_ARRAY: [campaignQuoteSection],
  CAMPAIGN_QUOTE_TEMPLATE: campaignQuoteTemplate,
  CAMPAIGN_QUOTE_TEMPLATE_ARRAY: [campaignQuoteTemplate],
  CAMPAIGN_QUOTE_TEMPLATE_ITEM: campaignQuoteTemplateItem,
  CAMPAIGN_QUOTE_TEMPLATE_ITEM_ARRAY: [campaignQuoteTemplateItem],
  CAMPAIGN_RURAL_CATEGORY: campaignRuralCategory,
  CAMPAIGN_RURAL_CATEGORY_ARRAY: [campaignRuralCategory],
  CAMPAIGN_SALE_METHOD: campaignSaleMethod,
  CAMPAIGN_SALE_METHOD_ARRAY: [campaignSaleMethod],
  CAMPAIGN_STATUS: campaignStatus,
  CAMPAIGN_STATUS_ARRAY: [campaignStatus],
  CAMPAIGN_TYPE: campaignType,
  CAMPAIGN_TYPE_ARRAY: [campaignType],
  CASE_STUDY: caseStudy,
  CASE_STUDY_ARRAY: [caseStudy],
  CHART: chart,
  CHART_ARRAY: [chart],
  COMMENT: comment,
  COMMENT_ARRAY: [comment],
  CONTACT: contact,
  CONTACT_ARRAY: [contact],
  CONTRACT: contract,
  CONTRACT_ARRAY: [contract],
  CONVERSATION: conversation,
  CONVERSATION_ARRAY: [conversation],
  COURIER: courier,
  COURIER_ARRAY: [courier],
  CREDIT_NOTE: creditNote,
  CREDIT_NOTE_ARRAY: [creditNote],
  CREDIT_NOTE_ITEM: creditNoteItem,
  CREDIT_NOTE_ITEM_ARRAY: [creditNoteItem],
  CUSTOM_DEADLINE: customDeadline,
  CUSTOM_DEADLINE_ARRAY: [customDeadline],
  CUSTOM_FIELD: customField,
  CUSTOM_FIELD_ARRAY: [customField],
  CUSTOM_FIELD_SET: customFieldSet,
  CUSTOM_FIELD_SET_ARRAY: [customFieldSet],
  CUSTOM_ITEM: customItem,
  CUSTOM_ITEM_ARRAY: [customItem],
  CUSTOM_ITEM_GROUP: customItemGroup,
  CUSTOM_ITEM_GROUP_ARRAY: [customItemGroup],
  CUSTOM_SHORTCODE: customShortcode,
  CUSTOM_SHORTCODE_ARRAY: [customShortcode],
  DATA_STORE_ITEM: dataStoreItem,
  DATA_STORE_ITEM_ARRAY: [dataStoreItem],
  DEFAULT_QUOTE_ITEM: defaultQuoteItem,
  DEFAULT_QUOTE_ITEM_ARRAY: [defaultQuoteItem],
  DIGITAL_COMPONENT: digitalComponent,
  DIGITAL_COMPONENT_ARRAY: [digitalComponent],
  DIGITAL_PAGE: digitalPage,
  DIGITAL_PAGE_ARRAY: [digitalPage],
  DIGITAL_TEMPLATE: digitalTemplate,
  DIGITAL_TEMPLATE_ARRAY: [digitalTemplate],
  DIGITAL_TEMPLATE_PAGE: digitalTemplatePage,
  DIGITAL_TEMPLATE_PAGE_ARRAY: [digitalTemplatePage],
  DIGITAL_TEMPLATE_PAGE_ITEM: digitalTemplatePageItem,
  DIGITAL_TEMPLATE_PAGE_ITEM_ARRAY: [digitalTemplatePageItem],
  DIGITAL_TEMPLATE_PAGE_SECTION: digitalTemplatePageSection,
  DIGITAL_TEMPLATE_PAGE_SECTION_ARRAY: [digitalTemplatePageSection],
  DIGITAL_TEMPLATE_PAGE_SECTION_GROUP: digitalTemplatePageSectionGroup,
  DIGITAL_TEMPLATE_PAGE_SECTION_GROUP_ARRAY: [digitalTemplatePageSectionGroup],
  DIRECTORY_LISTING: directoryListing,
  DIRECTORY_LISTING_ARRAY: [directoryListing],
  DIRECTORY_LISTING_TYPE: directoryListingType,
  DIRECTORY_LISTING_TYPE_ARRAY: [directoryListingType],
  DOCUMENT: document,
  DOCUMENT_ARRAY: [document],
  DOCUMENT_GROUP: documentGroup,
  DOCUMENT_GROUP_ARRAY: [documentGroup],
  DOCUMENT_SIGNER: documentSigner,
  DOCUMENT_SIGNER_ARRAY: [documentSigner],
  EVENT: event,
  EVENT_ARRAY: [event],
  FAVOURITE_ORDER: favouriteOrder,
  FAVOURITE_ORDER_ARRAY: [favouriteOrder],
  FAVOURITE_ORDER_ITEM: favouriteOrderItem,
  FAVOURITE_ORDER_ITEM_ARRAY: [favouriteOrderItem],
  FINANCE_APPLICATION: financeApplication,
  FINANCE_APPLICATION_ARRAY: [financeApplication],
  FONT: font,
  FONT_ARRAY: [font],
  FONT_FAMILY: fontFamily,
  FONT_FAMILY_ARRAY: [fontFamily],
  GEO_COUNTRY: geoCountry,
  GEO_COUNTRY_ARRAY: [geoCountry],
  GEO_STATE: geoState,
  GEO_STATE_ARRAY: [geoState],
  GEO_SUBURB: geoSuburb,
  GEO_SUBURB_ARRAY: [geoSuburb],
  IMAGE: image,
  IMAGE_ARRAY: [image],
  IMAGE_LIBRARY: imageLibrary,
  IMAGE_LIBRARY_ARRAY: [imageLibrary],
  INTEGRATION: integration,
  INTEGRATION_ARRAY: [integration],
  INTERACTIVE_IMAGE: interactiveImage,
  INTERACTIVE_IMAGE_ARRAY: [interactiveImage],
  INTERACTIVE_IMAGE_TAG: interactiveImageTag,
  INTERACTIVE_IMAGE_TAG_ARRAY: [interactiveImageTag],
  INVOICE: invoice,
  INVOICE_ARRAY: [invoice],
  INVOICE_ATTACHMENT: invoiceAttachment,
  INVOICE_ATTACHMENT_ARRAY: [invoiceAttachment],
  INVOICE_ITEM: invoiceItem,
  INVOICE_ITEM_ARRAY: [invoiceItem],
  LAYOUT_TEMPLATE: layoutTemplate,
  LAYOUT_TEMPLATE_ARRAY: [layoutTemplate],
  LOGIN_SESSION: loginSession,
  LOGIN_SESSION_ARRAY: [loginSession],
  MEDIA_LINK: mediaLink,
  MEDIA_LINK_ARRAY: [mediaLink],
  MICROSITE: microsite,
  MICROSITE_ARRAY: [microsite],
  MICROSITE_TEMPLATE: micrositeTemplate,
  MICROSITE_TEMPLATE_ARRAY: [micrositeTemplate],
  NOTIFICATION: notification,
  NOTIFICATION_ARRAY: [notification],
  ORDER: order,
  ORDER_ARRAY: [order],
  ORDER_BUNDLE: orderBundle,
  ORDER_BUNDLE_ARRAY: [orderBundle],
  ORDER_BUNDLE_ITEM: orderBundleItem,
  ORDER_BUNDLE_ITEM_ARRAY: [orderBundleItem],
  ORDER_ITEM: orderItem,
  ORDER_ITEM_ARRAY: [orderItem],
  ORDER_ITEM_TASK: orderItemTask,
  ORDER_ITEM_TASK_ARRAY: [orderItemTask],
  ORDER_ITEM_TASK_ATTACHMENT: orderItemTaskAttachment,
  ORDER_ITEM_TASK_ATTACHMENT_ARRAY: [orderItemTaskAttachment],
  ORDER_ITEM_TASK_TYPE: orderItemTaskType,
  ORDER_ITEM_TASK_TYPE_ARRAY: [orderItemTaskType],
  ORDER_LEAD_TIME: orderLeadTime,
  ORDER_LEAD_TIME_ARRAY: [orderLeadTime],
  ORDER_TRACKING_CODE: orderTrackingCode,
  ORDER_TRACKING_CODE_ARRAY: [orderTrackingCode],
  PAGINATION_RULE: paginationRule,
  PAGINATION_RULE_ARRAY: [paginationRule],
  PAYMENT_METHOD: paymentMethod,
  PAYMENT_METHOD_ARRAY: [paymentMethod],
  PORTAL: portal,
  PORTAL_ARRAY: [portal],
  PORTAL_OPTION: portalOption,
  PORTAL_OPTION_ARRAY: [portalOption],
  PORTAL_OPTION_VALUE: portalOptionValue,
  PORTAL_OPTION_VALUE_ARRAY: [portalOptionValue],
  PRICE_LIST: priceList,
  PRICE_LIST_ARRAY: [priceList],
  PRICING_PLAN: pricingPlan,
  PRICING_PLAN_ARRAY: [pricingPlan],
  PROCESSING_FEE: processingFee,
  PROCESSING_FEE_ARRAY: [processingFee],
  PROVIDER: provider,
  PROVIDER_ARRAY: [provider],
  PROVIDER_ACCOUNT: providerAccount,
  PROVIDER_ACCOUNT_ARRAY: [providerAccount],
  PROVIDER_DEADLINE_GROUP: providerDeadlineGroup,
  PROVIDER_DEADLINE_GROUP_ARRAY: [providerDeadlineGroup],
  PROVIDER_DISCLAIMER: providerDisclaimer,
  PROVIDER_DISCLAIMER_ARRAY: [providerDisclaimer],
  PROVIDER_ORDER_CODE: providerOrderCode,
  PROVIDER_ORDER_CODE_ARRAY: [providerOrderCode],
  PROVIDER_PACKAGE: providerPackage,
  PROVIDER_PACKAGE_ARRAY: [providerPackage],
  PROVIDER_PACKAGE_ITEM: providerPackageItem,
  PROVIDER_PACKAGE_ITEM_ARRAY: [providerPackageItem],
  PROVIDER_PRICE_LIST: providerPriceList,
  PROVIDER_PRICE_LIST_ARRAY: [providerPriceList],
  PROVIDER_PRICE_LIST_ITEM: providerPriceListItem,
  PROVIDER_PRICE_LIST_ITEM_ARRAY: [providerPriceListItem],
  PROVIDER_PRICE_LIST_ITEM_OPTION: providerPriceListItemOption,
  PROVIDER_PRICE_LIST_ITEM_OPTION_ARRAY: [providerPriceListItemOption],
  PROVIDER_PRICE_LIST_ITEM_OPTION_VALUE: providerPriceListItemOptionValue,
  PROVIDER_PRICE_LIST_PRODUCT_UNLOCK: providerPriceListProductUnlock,
  PROVIDER_PRICE_LIST_PRODUCT_UNLOCK_ARRAY: [providerPriceListProductUnlock],
  PROVIDER_PRODUCT_CODE: providerProductCode,
  PROVIDER_PRODUCT_CODE_ARRAY: [providerProductCode],
  PROVIDER_SERVICE: providerService,
  PROVIDER_SERVICE_ARRAY: [providerService],
  PROVIDER_TEMPLATE_PAGE: providerTemplatePage,
  PROVIDER_TEMPLATE_PAGE_ARRAY: [providerTemplatePage],
  PROVIDER_ZONE: providerZone,
  PROVIDER_ZONE_ARRAY: [providerZone],
  PROVIDER_ZONE_AGENCY_PRICE: providerZoneAgencyPrice,
  PROVIDER_ZONE_AGENCY_PRICE_ARRAY: [providerZoneAgencyPrice],
  PROVIDER_ZONE_SUBURB: providerZoneSuburb,
  PROVIDER_ZONE_SUBURB_ARRAY: [providerZoneSuburb],
  PUBLICATION: publication,
  PUBLICATION_ARRAY: [publication],
  PUBLICATION_BOOKING: publicationBooking,
  PUBLICATION_BOOKING_ARRAY: [publicationBooking],
  PUBLICATION_BOOKING_ITEM: publicationBookingItem,
  PUBLICATION_BOOKING_ITEM_ARRAY: [publicationBookingItem],
  PUBLICATION_COMPATIBLE_PAGINATION: publicationCompatiblePagination,
  PUBLICATION_COMPATIBLE_PAGINATION_ARRAY: [publicationCompatiblePagination],
  PUBLICATION_EDITION: publicationEdition,
  PUBLICATION_EDITION_ARRAY: [publicationEdition],
  PUBLICATION_INSPECTION: publicationInspection,
  PUBLICATION_INSPECTION_ARRAY: [publicationInspection],
  PUBLICATION_OPTION: publicationOption,
  PUBLICATION_OPTION_ARRAY: [publicationOption],
  PUBLICATION_OPTION_VALUE: publicationOptionValue,
  PUBLICATION_OPTION_VALUE_ARRAY: [publicationOptionValue],
  PUBLICATION_ORDER: publicationOrder,
  PUBLICATION_ORDER_ARRAY: [publicationOrder],
  PUBLICATION_PACKAGE: publicationPackage,
  PUBLICATION_PACKAGE_ARRAY: [publicationPackage],
  PUBLICATION_PACKAGE_PRODUCT: publicationPackageProduct,
  PUBLICATION_PACKAGE_PRODUCT_ARRAY: [publicationPackageProduct],
  PUBLICATION_PACKAGE_PUBLICATION: publicationPackagePublication,
  PUBLICATION_PACKAGE_PUBLICATION_ARRAY: [publicationPackagePublication],
  PUBLICATION_PACKAGE_RULE: publicationPackageRule,
  PUBLICATION_PACKAGE_RULE_ARRAY: [publicationPackageRule],
  PUBLICATION_PAGINATION_RULE: publicationPaginationRule,
  PUBLICATION_PAGINATION_RULE_ARRAY: [publicationPaginationRule],
  PUBLICATION_PRODUCT: publicationProduct,
  PUBLICATION_PRODUCT_ARRAY: [publicationProduct],
  PUBLICATION_SPECIAL_DEADLINE: publicationSpecialDeadline,
  PUBLICATION_SPECIAL_DEADLINE_ARRAY: [publicationSpecialDeadline],
  PUBLICATION_TEMPLATE_PAGE: publicationTemplatePage,
  PUBLICATION_TEMPLATE_PAGE_ARRAY: [publicationTemplatePage],
  REPORT: report,
  REPORT_ARRAY: [report],
  REVIEW: review,
  REVIEW_ARRAY: [review],
  ROLE: role,
  ROLE_ARRAY: [role],
  SAVED_ARTWORK: savedArtwork,
  SAVED_ARTWORK_ARRAY: [savedArtwork],
  SAVED_ARTWORK_PAGE: savedArtworkPage,
  SAVED_ARTWORK_PAGE_ARRAY: [savedArtworkPage],
  SCHEDULED_REMINDER: scheduledReminder,
  SCHEDULED_REMINDER_ARRAY: [scheduledReminder],
  SERVICE: service,
  SERVICE_ARRAY: [service],
  SERVICE_CATEGORY: serviceCategory,
  SERVICE_CATEGORY_ARRAY: [serviceCategory],
  SERVICE_PRODUCT: serviceProduct,
  SERVICE_PRODUCT_ARRAY: [serviceProduct],
  SERVICE_PRODUCT_TEMPLATE_PAGE: serviceProductTemplatePage,
  SERVICE_PRODUCT_TEMPLATE_PAGE_ARRAY: [serviceProductTemplatePage],
  SHARE_LINK: shareLink,
  SHARE_LINK_ARRAY: [shareLink],
  SMS_LOG: smsLog,
  SMS_LOG_ARRAY: [smsLog],
  STATUS: status,
  STATUS_ARRAY: [status],
  SUBSCRIBER: subscriber,
  SUBSCRIBER_ARRAY: [subscriber],
  SYSTEM: system,
  SYSTEM_ARRAY: [system],
  TAG: tag,
  TAG_ARRAY: [tag],
  TASK_LIST: taskList,
  TASK_LIST_ARRAY: [taskList],
  TASK_LIST_ITEM: taskListItem,
  TASK_LIST_ITEM_ARRAY: [taskListItem],
  TASK_LIST_SECTION: taskListSection,
  TASK_LIST_SECTION_ARRAY: [taskListSection],
  TASK_LIST_TEMPLATE: taskListTemplate,
  TASK_LIST_TEMPLATE_ARRAY: [taskListTemplate],
  TASK_LIST_TEMPLATE_ITEM: taskListTemplateItem,
  TASK_LIST_TEMPLATE_ITEM_ARRAY: [taskListTemplateItem],
  TASK_LIST_TEMPLATE_SECTION: taskListTemplateSection,
  TASK_LIST_TEMPLATE_SECTION_ARRAY: [taskListTemplateSection],
  TEAM: team,
  TEAM_ARRAY: [team],
  TEAM_MEMBER: teamMember,
  TEAM_MEMBER_ARRAY: [teamMember],
  TEMPLATE_PAGE: templatePage,
  TEMPLATE_PAGE_ARRAY: [templatePage],
  TEMPLATE_PAGE_ATTACHMENT: templatePageAttachment,
  TEMPLATE_PAGE_ATTACHMENT_ARRAY: [templatePageAttachment],
  TEMPLATE_PAGE_CATEGORY: templatePageCategory,
  TEMPLATE_PAGE_CATEGORY_ARRAY: [templatePageCategory],
  TEMPLATE_PAGE_ITEM: templatePageItem,
  TEMPLATE_PAGE_ITEM_ARRAY: [templatePageItem],
  TEMPLATE_PAGE_PARTIAL: templatePagePartial,
  TEMPLATE_PAGE_PARTIAL_ARRAY: [templatePagePartial],
  TRACKING_LINK: trackingLink,
  TRACKING_LINK_ARRAY: [trackingLink],
  TRAINING_COURSE: trainingCourse,
  TRAINING_COURSE_ARRAY: [trainingCourse],
  TRAINING_LESSON: trainingLesson,
  TRAINING_LESSON_ARRAY: [trainingLesson],
  TRAINING_MODULE: trainingModule,
  TRAINING_MODULE_ARRAY: [trainingModule],
  TRAINING_SESSION: trainingSession,
  TRAINING_SESSION_ARRAY: [trainingSession],
  USER: user,
  USER_ARRAY: [user],
  USER_VERIFICATION: userVerification,
  USER_VERIFICATION_ARRAY: [userVerification],
  WEBHOOK: webhook,
  WEBHOOK_ARRAY: [webhook],
}

export default Schemas
