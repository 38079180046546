import { encodeParams } from 'functions/utilities'

const testEnv = process.env.NODE_ENV === 'test'
const baseUrl = `${testEnv ? 'http://localhost' : ''}`

const baseConfig = {
  method: 'GET',
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
  },
}

const baseOptions = {
  v2: true,
}

const api = (url, options = {}, config = {}) => {
  const mergedOptions = { ...baseOptions, ...options }
  const mergedConfig = { ...baseConfig, ...config }

  // If we are upload a file using formData we need
  // to delete the content-type header so that the browser
  // adds it automatically for us with the correct boundaries.

  if (config.isFile){
    delete mergedConfig.headers['Content-Type']
    delete mergedConfig.isFile
  }

  const requestUrl = `${baseUrl}${url}?${encodeParams(mergedOptions)}`

  return fetch(requestUrl, mergedConfig)
}

export default api
