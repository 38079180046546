import { normalize } from 'normalizr'
import { Schemas } from '../../../shared/redux/schema'
import { updateEntities } from '../../../shared/redux/entity'
import { encodeParams } from '../../../shared/functions/utilities'

const FETCH_REQUEST = 'realhub/campaignCategory/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/campaignCategory/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/campaignCategory/FETCH_FAILURE'

// Initial State
const initialState = {
  result: [],
  loaded: false,
  loading: false,
  errors: [],
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
    loading: true
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    loading: false,
    loaded: true,
    errors: [],
    result: payload.result
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    loaded: false,
    loading: false,
    errors: errors
  }
}

// Action Creators
export function loadCampaignCategories(options){
  let config = {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    }
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(fetchRequest());

    // Encode Params
    let params = options ? `?${encodeParams(options)}` : '';

    let promise = fetch(`/admin/campaign_categories.json${params}`, config)
      .then(response => {
        return response.json().then( json => ({ json, response }) )
      })
      .then(({ json, response}) => {
        if(!response.ok){
          return Promise.reject(json);
        }

        let resultArray = getState().campaignCategories.result ? getState().campaignCategories.result.slice() : [];
        let index = resultArray.indexOf(json.id);
        resultArray = index !== -1 ? resultArray : resultArray.push(json.id);

        let normalizedJson = normalize(json, Schemas.CAMPAIGN_CATEGORY_ARRAY);
        dispatch(updateEntities(normalizedJson));
        dispatch(fetchSuccess({ result: resultArray }));
      })
      .catch(json => {
        let errors = json.errors ? json.errors : ["Something went wrong..."];
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch(action.type){
    case FETCH_REQUEST:
      return Object.assign({}, state, {
        loading: true
      });
    case FETCH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        loaded: true,
        result: action.result,
        errors: []
      });
    case FETCH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        loaded: false,
        errors: action.errors
      });
    default:
      return state;
  }
}