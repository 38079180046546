const CHANGE_FILTER = 'realhub/agencyDashboard/overview/signboards/CHANGE_FILTER'
const CHANGE_MULTIPLE_FILTERS = 'realhub/agencyDashboard/overview/signboards/CHANGE_MULTIPLE_FILTERS'

// Initial State
const initialState = {
  filterAgentIds: [],
  filterStatus: 'Installed',
  filterString: '',
}

// Actions
export function changeFilter(name, value){
  return {
    type: CHANGE_FILTER,
    name,
    value,
  }
}

export function changeMultipleFilters(filters = {}){
  return {
    type: CHANGE_MULTIPLE_FILTERS,
    filters,
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type } = action

  switch (type){
    case CHANGE_FILTER:{
      const change = {}
      change[action.name] = action.value
      return Object.assign({}, state, change)
    }
    case CHANGE_MULTIPLE_FILTERS:{
      return Object.assign({}, state, action.filters)
    }
    default:
      return state
  }
}
