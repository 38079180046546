import { combineReducers } from 'redux'

import auctions from './auctions'
import deadlines from './deadlines'
import inspections from './inspections'
import orders from './orders'
import signboards from './signboards'

export default combineReducers({
  auctions,
  deadlines,
  inspections,
  orders,
  signboards,
})
