const CHANGE_FILTER = 'realhub/agencyDashboard/overview/inspections/CHANGE_FILTER'

// Initial State
const initialState = {
  filterString: '',
}

// Inspections
export function changeFilter(name, value){
  return {
    type: CHANGE_FILTER,
    name,
    value,
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type } = action

  switch (type){
    case CHANGE_FILTER:{
      const change = {}
      change[action.name] = action.value
      return Object.assign({}, state, change)
    }
    default:
      return state
  }
}
