const loggableActionTypes = [
  'realhub/entity/UPDATE_ENTITY',
  'realhub/entity/DELETE_ENTITY',
]

export default store => next => (action) => {
  next(action)

  if (action && loggableActionTypes.includes(action.type)){
    const currentTimestamp = new Date().getTime()
    const updatedEntityKeys = Object.keys(action).filter(key => key !== 'type')

    const updatesAction = {
      type: 'realhub/entity/UPDATE_ENTITY',
      _updates: updatedEntityKeys.reduce((acc, key) => {
        acc[key] = currentTimestamp
        return acc
      }, {}),
    }

    return next(updatesAction)
  }
}
