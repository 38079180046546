import deepmerge from 'deepmerge'

// EXPORTING ACTIONS FOR TEST PURPOSES
export const constants = {
  DELETE_ENTITY: 'realhub/entity/DELETE_ENTITY',
  REPLACE_ENTITY: 'realhub/entity/REPLACE_ENTITY',
  UPDATE_ENTITY: 'realhub/entity/UPDATE_ENTITY',
}

const { DELETE_ENTITY, REPLACE_ENTITY, UPDATE_ENTITY } = constants

const initialState = {
  _updates: {},
  activities: {},
  adCampaigns: {},
  adCopies: {},
  addresses: {},
  agencies: {},
  agencyContacts: {},
  agencyOrderBundles: {},
  agencyPaymentMethods: {},
  agencyPortals: {},
  agencyProviderDeadlineGroups: {},
  agencyProviderPackages: {},
  agencyProviderPriceOverrides: {},
  agencyProviders: {},
  agencyPublications: {},
  agencyTags: {},
  agencyTemplatePages: {},
  agents: {},
  apiKeys: {},
  apiLogs: {},
  appOrderPrices: {},
  artworkComments: {},
  artworkPageItems: {},
  artworkPages: {},
  artworkReviews: {},
  artworks: {},
  assetLibraries: {},
  attachments: {},
  bookings: {},
  brandColours: {},
  brandGroupMembers: {},
  brandGroupProviders: {},
  brandGroupPublications: {},
  brandGroups: {},
  brandThemeTemplatePages: {},
  brandThemes: {},
  brands: {},
  campaignCategories: {},
  campaignCommercialCategories: {},
  campaignComparables: {},
  campaignContacts: {},
  campaignContributions: {},
  campaignDocuments: {},
  campaignInspections: {},
  campaignItems: {},
  campaignNotes: {},
  campaignPortalSyncs: {},
  campaignPortals: {},
  campaignProposalSections: {},
  campaignProposals: {},
  campaignQuoteItems: {},
  campaignQuoteSections: {},
  campaignQuoteTemplateItems: {},
  campaignQuoteTemplates: {},
  campaignQuotes: {},
  campaignRuralCategories: {},
  campaignSaleMethods: {},
  campaignStatuses: {},
  campaignTypes: {},
  campaigns: {},
  caseStudies: {},
  charts: {},
  comments: {},
  contacts: {},
  contracts: {},
  conversations: {},
  couriers: {},
  creditNoteItems: {},
  creditNotes: {},
  customDeadlines: {},
  customFieldSets: {},
  customFields: {},
  customItemGroups: {},
  customItems: {},
  customShortcodes: {},
  dataStoreItems: {},
  defaultQuoteItems: {},
  digitalComponents: {},
  digitalPages: {},
  digitalTemplatePageItems: {},
  digitalTemplatePageSectionGroups: {},
  digitalTemplatePageSections: {},
  digitalTemplatePages: {},
  digitalTemplates: {},
  directoryListingTypes: {},
  directoryListings: {},
  documentGroups: {},
  documentSigners: {},
  documents: {},
  events: {},
  favouriteOrderItems: {},
  favouriteOrders: {},
  financeApplications: {},
  fontFamilies: {},
  fonts: {},
  geoCountries: {},
  geoStates: {},
  geoSuburbs: {},
  imageLibraries: {},
  images: {},
  integrations: {},
  interactiveImageTags: {},
  interactiveImages: {},
  invoiceAttachments: {},
  invoiceItems: {},
  invoices: {},
  layoutTemplates: {},
  loginSessions: {},
  mediaLinks: {},
  microsites: {},
  micrositeTemplates: {},
  notifications: {},
  orderBundleItems: {},
  orderBundles: {},
  orderItemTaskAttachments: {},
  orderItemTaskTypes: {},
  orderItemTasks: {},
  orderItems: {},
  orderLeadTimes: {},
  orderTrackingCodes: {},
  orders: {},
  paginationRules: {},
  paymentMethods: {},
  portalOptionValues: {},
  portalOptions: {},
  portals: {},
  pricingPlans: {},
  processingFees: {},
  providerAccounts: {},
  providerDeadlineGroups: {},
  providerDisclaimers: {},
  providerOrderCodes: {},
  providerPackageItems: {},
  providerPackages: {},
  providerPriceListItemOptionValues: {},
  providerPriceListItemOptions: {},
  providerPriceListItems: {},
  providerPriceListProductUnlocks: {},
  providerPriceLists: {},
  providerProductCodes: {},
  providerServices: {},
  providerTemplatePages: {},
  providerZoneAgencyPrices: {},
  providerZoneSuburbs: {},
  providerZones: {},
  providers: {},
  publicationBookingItems: {},
  publicationBookings: {},
  publicationCompatiblePaginations: {},
  publicationEditions: {},
  publicationInspections: {},
  publicationOptionValues: {},
  publicationOptions: {},
  publicationOrders: {},
  publicationPackageProducts: {},
  publicationPackagePublications: {},
  publicationPackageRules: {},
  publicationPackages: {},
  publicationPaginationRules: {},
  publicationProducts: {},
  publicationSpecialDeadlines: {},
  publicationTemplatePages: {},
  publications: {},
  reports: {},
  reviews: {},
  roles: {},
  savedArtworkPages: {},
  savedArtworks: {},
  scheduledReminders: {},
  serviceCategories: {},
  serviceProductTemplatePages: {},
  serviceProducts: {},
  services: {},
  shareLinks: {},
  smsLogs: {},
  statuses: {},
  subscribers: {},
  systems: {},
  tags: {},
  taskListItems: {},
  taskListSections: {},
  taskListTemplateItems: {},
  taskListTemplateSections: {},
  taskListTemplates: {},
  taskLists: {},
  teamMembers: {},
  teams: {},
  templatePageAttachments: {},
  templatePageCategories: {},
  templatePageItems: {},
  templatePagePartials: {},
  templatePages: {},
  trackingLinks: {},
  trainingCourses: {},
  trainingLessons: {},
  trainingModules: {},
  trainingSessions: {},
  userVerifications: {},
  users: {},
  webhooks: {},
}

export function updateEntities(payload){
  return { type: UPDATE_ENTITY, ...payload.entities }
}

export function replaceEntity(payload){
  return { type: REPLACE_ENTITY, ...payload.entities }
}

export function deleteEntity(payload){
  return { type: DELETE_ENTITY, ...payload.entities }
}

function cleanKeys(keys = [], removeKeys = []){
  removeKeys.forEach((removeKey) => {
    const index = keys.indexOf(removeKey)
    keys.splice(index, 1)
  })

  return keys
}

// Reducers
function addEntities(state, action){
  const keys = cleanKeys(Object.keys(action), ['type'])
  const newState = { ...state }

  // Don't merge arrays
  const dontMerge = (destination, source) => source
  const mergeOptions = { arrayMerge: dontMerge }

  keys.forEach((key) => {
    newState[key] = deepmerge(newState[key], action[key], mergeOptions)
  })
  return newState
}

function replaceEntities(state, action){
  const keys = cleanKeys(Object.keys(action), ['type'])
  const newState = { ...state }

  keys.forEach((key) => {
    if (newState[key]){
      newState[key] = { ...state[key] }

      // Keys of the item we need to replace
      const itemKeys = Object.keys(action[key])
      itemKeys.forEach((itemKey) => {
        newState[key][itemKey] = action[key][itemKey]
      })
    }
  })

  return newState
}

function removeEntities(state, action){
  const keys = cleanKeys(Object.keys(action), ['type'])
  const newState = { ...state }

  keys.forEach((key) => {
    if (newState[key]){
      newState[key] = { ...state[key] }

      // Keys of the item we need to remove
      const itemKeys = Object.keys(action[key])
      itemKeys.forEach((itemKey) => {
        delete newState[key][itemKey]
      })
    }
  })

  return newState
}

export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case UPDATE_ENTITY:
      return addEntities(state, action)
    case REPLACE_ENTITY:
      return replaceEntities(state, action)
    case DELETE_ENTITY:
      return removeEntities(state, action)
    default:
      return state
  }
}
