import { normalize } from 'normalizr'
import { Schemas } from 'redux-modules/schema'
import { updateEntities } from 'redux-modules/entity'

import api from 'api'

const FETCH_REQUEST = 'realhub/serviceCategory/FETCH_REQUEST'
const FETCH_SUCCESS = 'realhub/serviceCategory/FETCH_SUCCESS'
const FETCH_FAILURE = 'realhub/serviceCategory/FETCH_FAILURE'
const CREATE_REQUEST = 'realhub/serviceCategory/CREATE_REQUEST'
const CREATE_SUCCESS = 'realhub/serviceCategory/CREATE_SUCCESS'
const CREATE_FAILURE = 'realhub/serviceCategory/CREATE_FAILURE'
const UPDATE_REQUEST = 'realhub/serviceCategory/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realhub/serviceCategory/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realhub/serviceCategory/UPDATE_FAILURE'

// Initial State
const initialState = {
  result: [],
  loaded: false,
  loading: false,
  loadedServiceCategoryIds: [],
  creating: false,
  errors: [],
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
    loading: true,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    loading: false,
    loaded: true,
    errors: [],
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    loaded: false,
    loading: false,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(payload = {}){
  return {
    type: CREATE_SUCCESS,
    result: payload.result,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(payload = {}){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Helper Functions
const handleError = errors => errors || ['Something went wrong']

// Action Creators
export function loadServiceCategories(options){
  return (dispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/admin/service_categories.json', options)
      .then(response => (
        response.json().then(json => ({ json, response }))
      ))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.SERVICE_CATEGORY_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result: normalizedJson.result }))
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function loadServiceCategory(service_category, options){
  return (dispatch, getState) => {
    const service_categories = getState().serviceCategories

    // Set Loading
    const loadedServiceCategoryIds = service_categories.loadedServiceCategoryIds
      ? service_categories.loadedServiceCategoryIds.slice() : []

    if (!loadedServiceCategoryIds.includes(service_category.id)){
      loadedServiceCategoryIds.push(service_category.id)
    }

    dispatch(fetchRequest({ loadedServiceCategoryIds }))

    const promise = api(`/admin/service_categories/${service_category.id}.json`, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        // Add to result array
        const result = service_categories.result ? service_categories.result.slice() : []
        if (!result.includes(json.id)){
          result.push(json.id)
        }

        const normalizedJson = normalize(json, Schemas.SERVICE_CATEGORY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess({ result }))
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(fetchFailure(errors))
      })

    return promise
  }
}

export function createServiceCategory(service_category, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      service_category,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(createRequest())

    const promise = api('/admin/service_categories.json', options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.SERVICE_CATEGORY)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess(normalizedJson))
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(createFailure(errors))
      })

    return promise
  }
}

export function updateServiceCategory(service_category, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      service_category,
    }),
  }

  return (dispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/admin/service_categories/${service_category.id}.json`, options, config)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok){
          return Promise.reject(json)
        }

        const normalizedJson = normalize(json, Schemas.SERVICE_CATEGORY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: json }
      })
      .catch((json) => {
        const errors = handleError(json.errors)
        dispatch(updateFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return { ...state, loading: true }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: action.result,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return { ...state, creating: true }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        result: action.result,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
