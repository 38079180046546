import { combineReducers } from 'redux'

import campaignDashboard from './campaignDashboard'
import dashboard from './dashboard'
import overview from './overview'

export default combineReducers({
  campaignDashboard,
  dashboard,
  overview,
})
